import { createSelector } from 'reselect';
export const getDoneList = createSelector(
  state => state.TodoList.items.filter(i => { return i.done }),
  items => {
    return items;
  }
);
export const getUndoList = createSelector(
  state => state.TodoList.items.filter(i => { return !i.done }),
  items => {
    return items;
  }
);
export const getInputMessage = createSelector(
  state => state.TodoList.inputNote,
  text => {
    return text;
  }
);