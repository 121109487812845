import styled, { css } from 'styled-components';
export const ListContentRight = styled.div`
  flex: 1 auto;
`
export const ListContentLeft = styled.div`
  flex: 9 auto;
`
export const InputContentRight = styled.div`
  flex: 1 auto;
`
export const InputContentLeft = styled.div`
  flex: 3 auto;
`


export const CustomInputButton = styled.button`
  border-radius: 54px;
  height: 63px;
  width: -webkit-fill-available;
  background: #8080803d;
  border: 0px;
  font-size: 20px;
  padding: 20px 31px;
  margin: 5px 10px;
  -moz-box-shadow: 1px 1px 26px #a3a3a34f;
  -webkit-box-shadow: 1px 1px 26px #a3a3a34f;
  box-shadow: 1px 1px 26px #a3a3a34f;
  :hover{
    background: #808080a3;
  }
`;

export const NoteParagraph = styled.p`
  text-align: left;
  padding-left:21px;
  font-size: 20px;
  margin: 10px 0px;
  ${props => props.strike && css`
    text-decoration: line-through;
    color: #afafaf;
  `}
`;

export const BodySection = styled.section`
  display:flex;
  flex-direction: column
`
export const MainSection = styled.section`
  overflow-y: scroll;
  height: calc(84vh - 97px);
  flex: 10;
`
export const InputFooter = styled.div`
  flex:1;
  display: flex;
  margin: 5px 10px;
  bottom: 14px;
  width: 97vw;
  max-width:700px;
  left: 0;
  right: 0;
  margin: 5px auto;
`;