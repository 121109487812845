import styled from 'styled-components';
export const InputStyle = styled.input.attrs({
  // we can define static props
  placeholder: "Type your note...",

  // or we can define dynamic ones
  margin: props => props.size || "1em",
  padding: props => props.size || "1em"
})`
  border-radius: 54px;
  border: 0px;
  font-size: 20px;
  padding: 20px 31px;
  margin: 5px 10px;
  background: white;
  -moz-box-shadow: 1px 1px 26px #a3a3a39e;
  -webkit-box-shadow: 1px 1px 26px #a3a3a39e;
  box-shadow: 1px 1px 26px #a3a3a39e;
  width: -webkit-fill-available;
  width: -webkit-fill-available;
  @media (max-width: 467px) {
    width: 65%;
  }
`