import {
  UNDO_ITEM_ACTION,
  DELETE_ITEM_ACTION,
  SAVE_ITEM_ACTION,
  DONE_ITEM_ACTION,
  ON_INPUT_NOTE_CHANGE_ACTION
} from './../../action-types/TodoList';
export const doneItemAction = (item) => {
  return { type: DONE_ITEM_ACTION, item };
}
export const undoItemAction = (item) => {
  return { type: UNDO_ITEM_ACTION, item };
}
export const deleteItemAction = (item) => {
  return { type: DELETE_ITEM_ACTION, item };
}
export const saveItemAction = (text) => {
  return { type: SAVE_ITEM_ACTION, text };
}
export const onInputNoteChangeAction = (text) => {
  return { type: ON_INPUT_NOTE_CHANGE_ACTION, text };
}