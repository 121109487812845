import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm } from 'redux-form/immutable';
import TodoListDisplay from './TodoListDisplay';
import { getDoneList, getUndoList, getInputMessage } from './../../selectors/TodoList';
import * as todoListActions from './../../actions/TodoList';
class TodoListContainer extends PureComponent {
  doneItemHandler = (item) => {
    this.props.actions.doneItemAction(item);
  }
  undoItemHandler = (item) => {
    this.props.actions.undoItemAction(item);
  }
  deleteItemHandler = (item) => {
    this.props.actions.deleteItemAction(item);
  }
  saveItemHandler = () => {
    this.props.actions.saveItemAction();
  }
  onInputNoteChangeHandler = (e) => {
    this.props.actions.onInputNoteChangeAction(e.target.value);
  }
  render() {
    const todoListProps = {
      doneItemAction: this.doneItemHandler,
      undoItemAction: this.undoItemHandler,
      deleteItemAction: this.deleteItemHandler,
      saveItemAction: this.saveItemHandler,
      onInputNoteChangeAction: this.onInputNoteChangeHandler,

      doneItems: this.props.doneItems,
      undoItems: this.props.undoItems,
      inputNote: this.props.inputNote
    };
    return (
      <TodoListDisplay {...todoListProps} />
    );
  }
}

const DecorateTodoList = reduxForm({
  form: 'TodoListForm'
})(TodoListContainer);

const mapStateToProps = (state) => {
  return {
    doneItems: getDoneList(state),
    undoItems: getUndoList(state),
    inputNote: getInputMessage(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(todoListActions, dispatch)
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(DecorateTodoList);