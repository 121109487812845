import React from 'react';
import CustomList from './../common/CustomList';
import CustomListItem from './../common/CustomListItem';
import CustomButton from './../common/CustomButton';
import CustomTextInput from './../common/CustomTextInput';
import { FaCheck, FaTrash, FaRedo } from 'react-icons/fa';
import { BodySection, MainSection, ListContentLeft, ListContentRight, InputFooter, CustomInputButton, InputContentLeft, InputContentRight, NoteParagraph } from './styled';

const TodoListDisplay = (props) => {
  const {
    doneItems,
    undoItems,
    inputNote,
    doneItemAction,
    undoItemAction,
    deleteItemAction,
    saveItemAction,
    onInputNoteChangeAction
  } = props;
  const DoneItems = doneItems.map(i => {
    return (
      <CustomListItem key={i.id}>
        <ListContentLeft>
          <NoteParagraph strike="true">{i.text}</NoteParagraph>
        </ListContentLeft>
        <ListContentRight>
          <CustomButton info onClick={() => { undoItemAction(i) }}><FaRedo /></CustomButton>
          <CustomButton warning onClick={() => { deleteItemAction(i) }}><FaTrash /></CustomButton>
        </ListContentRight>
      </CustomListItem>
    );
  });
  const UndoItems = undoItems.map(i => {
    return (
      <CustomListItem key={i.id}>
        <ListContentLeft>
          <NoteParagraph>{i.text}</NoteParagraph>
        </ListContentLeft>
        <ListContentRight>
          <CustomButton success onClick={() => { doneItemAction(i) }}><FaCheck /></CustomButton>
          <CustomButton warning onClick={() => { deleteItemAction(i) }}><FaTrash /></CustomButton>
        </ListContentRight>
      </CustomListItem>
    );
  });
  return (
    <BodySection>
      <MainSection>
        <CustomList>
          {UndoItems}
        </CustomList>
        <h2>Done</h2>
        <hr />
        <CustomList>
          {DoneItems}
        </CustomList>
      </MainSection>
      <InputFooter>
        <InputContentLeft>
          <CustomTextInput onChange={onInputNoteChangeAction} value={inputNote} />
        </InputContentLeft>
        <InputContentRight>
          <CustomInputButton onClick={saveItemAction}>ADD</CustomInputButton>
        </InputContentRight>
      </InputFooter>
    </BodySection>
  );
}
export default TodoListDisplay;