export const todoList = {
  inputNote: "",
  items: [
    {
      id: 'lokjnbvfds',
      text: 'Buy the christmas\'s presents',
      done: false
    },
    {
      id: 'olskieurjn',
      text: 'Send the ticket for the housing',
      done: false
    },
    {
      id: 'aqyujcmdki',
      text: 'Appoiment with the doctor at 3:00pm',
      done: true
    }
  ]
}
export const defaultState = {
  todoList,
}