import styled, { css } from 'styled-components';
export const ButtonStyle = styled.button`
  height: 50px;
  width: 50px;
  background: #8080803d;
  border: 0px;
  border-radius: 50%;
  :hover{
    background: #808080a3;
  }
  margin: 0 2px;
  ${props => props.success && css`
    :hover{
      background: #2ab122ba;
    }
    color: #white;
  `}
  ${props => props.warning && css`
    :hover{
      background: #ec2c2cba;
    }
    color: #white;
  `}
  ${props => props.info && css`
    :hover{
      background: #61dafb;
    }
    color: #white;
  `}
`
