import React, { Component } from 'react';

import './App.css';
import { NavBar } from './componets/Navbar'
import { TodoList } from './componets/TodoList'

class App extends Component {
  render() {
    return (
      <div className="App">
        <header className="App-header">
          <NavBar></NavBar>
        </header>
        <TodoList />

      </div>
    );
  }
}

export default App;
