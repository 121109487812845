import { todoList as defaultState } from './../../defaultState';
//import { fromJS } from 'immutable';
import {
  UNDO_ITEM_ACTION,
  DELETE_ITEM_ACTION,
  SAVE_ITEM_ACTION,
  DONE_ITEM_ACTION,
  ON_INPUT_NOTE_CHANGE_ACTION
} from './../../action-types/TodoList';

export const TodoListReducer = (state = defaultState, action) => {
  switch (action.type) {
    case DONE_ITEM_ACTION: {
      let newState = { ...state };
      newState.items = newState.items.filter(i => { return i.id !== action.item.id });
      const newItem = Object.assign({}, action.item);
      newItem.done = true;
      newState.items.push(newItem);
      return newState;
    }
    case UNDO_ITEM_ACTION: {
      let newState = { ...state };
      newState.items = newState.items.filter(i => { return i.id !== action.item.id });
      const newItem = Object.assign({}, action.item);
      newItem.done = false;
      newState.items.push(newItem);
      return newState;
    }
    case DELETE_ITEM_ACTION: {
      let newState = { ...state };
      newState.items = newState.items.filter(i => { return i.id !== action.item.id });
      return newState;
    }
    case SAVE_ITEM_ACTION: {
      let newState = { ...state };
      newState.items = [
        ...newState.items,
        {
          id: "" + Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10),
          text: "" + newState.inputNote,
          done: false
        }
      ];
      newState.inputNote = "";
      return newState;
    }
    case ON_INPUT_NOTE_CHANGE_ACTION: {
      let newState = { ...state };
      newState.inputNote = action.text + "";
      return newState;
    }
    default:
      return state;
  }
}